<div class="ama-typeahead-container" data-test="typeahead-container">
  <app-typeahead (searchSubmit)="search($event)" data-test="typeahead"></app-typeahead>
  <div>
    <div class="search-bar__hint">
      <div class="search-bar__hint--text" data-test="searchHint">
        Not sure what to search?
      </div>
      <div class="search-bar__hint-view-all" (click)="openSpecialtiesModal()" data-test="viewAllSpecialtiesLink">
        View All Specialties
      </div>
    </div>
    <div class="search-bar__guide-button">
      <ama-button
        class="freida-primary-solid"
        text="View Specialty Guide"
        buttonTheme="primary"
        link="true"
        url="/specialty">
      </ama-button>
    </div>
    
    <app-browse-specialties
      (searchClicked)="search($event)"
      (closeModal)="afterModalClose($event)"
      [isModalOpen]="isModalOpen">
    </app-browse-specialties>
  </div>
</div>
